
import React from 'react'
import Layout from '../components/layout'
import '../styles/legal-and-disclaimer.scss'

const disclaimers = [
  "The COVID-19 Convalescent Plasma (CCP) Treatment Benefit Index (TBI) is a clinical tool intended for use only by healthcare professionals. THE INFORMATION PROVIDED BY THIS TOOL IS NOT FOR MEDICAL ADVICE AND CANNOT BE USED TO DIAGNOSE OR TREAT ANY MEDICAL CONDITION. The TBI is not a replacement for experienced clinical judgment. Physicians and other healthcare professionals who use the tool should exercise their clinical judgment as to the information it provides. Non-medical professionals who use the TBI and its clinical tool do so at their own risk.  Individuals with any type of medical condition are specifically cautioned to seek professional medical advice before beginning any sort of health treatment.",
 
"The TBI, together with the contents of this site (including any text, graphics, and images), are for informational purposes only. The information on this site has been obtained from peer-reviewed research sources and is believed to be reliable. However, due to the constantly developing nature of medical information regarding COVID-19, neither the creators of the TBI nor NYU Grossman School of Medicine or NYU Langone Health guarantee or warrant the accuracy, timeliness, currency, or completeness of the TBI or the information contained on this site or its value or use to anyone.  Any user of the TBI and other material on this site assumes full responsibility for any actions taken from their use. The creators of the TBI, NYU Grossman School of Medicine, and NYU Langone Health cannot and will not be held legally, financially, or medically responsible for decisions made following any use of the TBI or in reliance on any content on this site and for any damage or injury (including death) to the user, other persons, or property arising therefrom.",
  ,
]

export default function Home() {
  return (
    <Layout>
      <div className="home-hero-container">
        <h1 className="text-center pt-4 pt-lg-5">Disclaimer</h1>
        <p className="t-body1 text-center mt-3">Effective Date: Sept 07, 2021</p>
        <div className="container-disclaimer pb-4 pb-lg-5">
          {disclaimers.map((dis, idx) => (
            <p key={idx} className="disclaimer t-body1">{dis}</p>
          ))}
        </div>
        <h1 className="text-center pt-4 pt-lg-5">Copyright</h1>
        <p className="t-body1 text-center mt-3">Effective Date: Sept 07, 2021</p>

        <div className="container-disclaimer pb-4 pb-lg-5">
        Unless otherwise noted, the TBI and all information presented is owned by NYU Langone Health and is Copyright, NYU Langone Health, All Rights Reserved.  Use of the TBI and other materials on this site is personal to users and readers.  Use of the TBI or other materials for commercial purposes, other than those approved by TBI’s creators and NYU Langone Health, is not permitted.
          </div>
      </div>
    </Layout>
  )
}
